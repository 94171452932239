import React from 'react';
import { graphql } from 'gatsby';
import { usePageTitle } from '../../hooks/usePageTitle';
import { BlockContent } from '../../components/block-content';
import HeaderPadding from '../../layout/HeaderPadding';
import { Areas } from '../../components/Areas';
import SEO from '../../components/seo';
import { useContext } from 'react';
import { LanguageContext } from '../../layout/Layout';
import { InstagramFeed } from '../../components/InstagramFeed';
import { ContentBlock } from '../../components/ContentBlock';
import { GyroLogo } from '@components/animation/GyroLogo';

export const query = graphql`
  query AboutPageQueryEn {
    about: sanityAboutPage(_id: { regex: "/(drafts.|)about-page/" }) {
      title {
        nb
        en
      }
      _rawBody
    }
  }
`;

const AboutPage = ({ data: { about } }) => {
  const lang = useContext(LanguageContext);

  const title = (about.title && about.title[lang]) || (about.title && about.title['nb']);
  const body = (about._rawBody && about._rawBody[lang]) || (about._rawBody && about._rawBody['nb']) || [];

  usePageTitle(title);

  return (
    <React.Fragment>
      <SEO title={title} />
      <GyroLogo />
      {/* <HeaderPadding /> */}
      <BlockContent blocks={body} />
      {/* <ContentBlock doubleTop>
      <Areas />
    </ContentBlock> */}
      <InstagramFeed />
    </React.Fragment>
  );
};

export default AboutPage;
